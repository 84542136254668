import { RouteComponentProps, Router, useLocation } from '@gatsbyjs/reach-router';
import { APCenter, APColumn, APImage, APSizedBox, APTag, APText, getIt, showErrorDialog } from 'ap-components';
import { useEffect, useState } from 'react';
import MutualFundApiServices from '../../Services/MutualFundServices';
import { KYCDigilockerSchema } from '../../Services/APIClasses';

export function SuccessPage(props: RouteComponentProps) {
    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch' style={{ width: "100vw" }} mainAxisAlignment="center">
            <APColumn>
                <APImage src="/icons/greenCheck.svg" height="80px" width="80px" />
                <APSizedBox height="32px" />
                <APText>
                    Your KYC Application is submitted successfully.
                </APText>
                <APSizedBox height="6px" />
                <APText>
                    You can now close this page.
                </APText>
            </APColumn>
        </APColumn>
    )
}

export function FailurePage(props: RouteComponentProps) {
    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch' style={{ width: "100vw" }} mainAxisAlignment="center">
            <APColumn>
                <APSizedBox height="32px" />
                <APText>
                    Your KYC Application submission failed.
                </APText>
                <APSizedBox height="6px" />
                <APText>
                    You can now close this page.
                </APText>
            </APColumn>
        </APColumn>
    )
}



export default function NipponKYCPage(props: RouteComponentProps) {
    {/* <SuccessResponse path='/success'></SuccessResponse> */ }
    return <Router style={{ display: "flex", height: "100%" }}>
        <NipponKYCStartPage path="/" default />
        <SuccessPage path="/success" />
        <FailurePage path="/failed" />
    </Router>
}
export function NipponKYCStartPage(props: RouteComponentProps) {

    const location = useLocation();

    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (!token) {
        return <APCenter><APTag variant='negative'>Invalid user</APTag></APCenter>
    }


    async function refetch() {
        try {
            let redirUrl = await getIt(MutualFundApiServices).nipponKyc({ token: token! });
            window.open(redirUrl, "_blank");
        } catch (err: any) {
            showErrorDialog(err.message)
        }
    }

    useEffect(() => {
        refetch();
    }, []);

    return <APCenter>
        <APText>Loading...</APText>
    </APCenter>
}
