import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { APButton, APCard, APColumn, APDialogCard, APExpanded, APForm, APFormFieldRadio, APFormFieldText, APImage, APOtp, APPalette, APRow, APSizedBox, APText, Deferred, getIt, launchDialog, showErrorDialog, showSnackbar, useAPForm, useCountDown } from 'ap-components'
import { useEffect, useState } from 'react'
import MutualFundApiServices from '../../Services/MutualFundServices';
import { launchPage } from '../../Common/Utils';
import showAlertSnackBarConfirmation from 'ap-components/src/dialogs/showAPAlertSnackBar';
import { DeletionAccountDetails } from '../../Services/APIClasses';

export default function ApproveAccountDeletion(props: RouteComponentProps<{ requestId: string }>) {

  const requestId = props.requestId;
  const control = useAPForm();

  const [showFeedBackPage, setShowFeedBackPage] = useState<Boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("Duplicate Account");
  const [customReason, setCustomReason] = useState<string>("");
  const [accountDetails, setAccountDetails] = useState<undefined | DeletionAccountDetails>();
  const [initialDataError, setInitialDataError] = useState<string | undefined>();

  async function loadInitialData() {
    try {
      if (!requestId || requestId === "success" || requestId === "rejected") return;

      const data = await getIt(MutualFundApiServices).getDeletionAccountDetails(requestId);
      setAccountDetails(data);
    } catch (error: any) {
      setInitialDataError(error.message);
    }
  }


  useEffect(() => {
    loadInitialData();
  }, [requestId]);


  if (requestId === "success")
    return <EmptyScreenWithMessage message="Account successfully deleted." subMessage='You may now close this window' />

  if (requestId === "rejected")
    return <EmptyScreenWithMessage message="Account Deletion Rejected" subMessage='You may now close this window' />

  if (initialDataError)
    return <EmptyScreenWithMessage message={initialDataError} subMessage='You may now close this window' />

  if (!requestId || !accountDetails)
    return <EmptyScreenWithMessage message="Loading.." />


  if (accountDetails?.accountDeletedAlready == true) {
    return <EmptyScreenWithMessage message="Account already deleted" subMessage='You may now close this window' />
  }



  return (
    <APExpanded>
      <APColumn style={{ padding: "32px 16px", backgroundColor: APPalette["grey-50"] }} mainAxisSize="max" crossAxisAlignment="stretch">
        <APRow mainAxisAlignment='center'>
          <APImage width={181} height={24} src='/Assetplus_Logo.png' />
        </APRow>
        <APSizedBox height='28px' />

        {!showFeedBackPage ? <>
          <APRow>
            <APText variant="paragraph-large">{`Hi , ${accountDetails?.fullName} following actions will be initiated after your approval. Please check all details before approving:`}</APText>
          </APRow>
          <APSizedBox height='16px' />


          <APRow mainAxisSize='max' mainAxisAlignment="spaceBetween" crossAxisAlignment="start" >

            <APColumn crossAxisAlignment='stretch' gap='8px'>
              <APText variant="h3">Details</APText>
              <APText variant="paragraph-large">Account Deletion</APText>
            </APColumn>

            <APColumn crossAxisAlignment='end'>
              <APText variant="h3">Approve</APText>
            </APColumn>
          </APRow>

          <APSizedBox height='38px' />

          <APRow mainAxisSize='max' gap='16px' mainAxisAlignment='center'>
            <APButton type="secondary" onClick={async () => {
              try {
                await getIt(MutualFundApiServices).rejectAccountDeletion({ requestId });
                showAlertSnackBarConfirmation({ content: "Request rejected", intent: "positive", emphasis: "intense" });
                launchPage("rejected");
              } catch (error: any) {
                showErrorDialog(error.message);
              }
            }} style={{ color: APPalette['grey-700'] }}>Reject</APButton>
            <APButton type="primary" style={{ backgroundColor: "#00B0FF" }} onClick={() => {
              setShowFeedBackPage(true);
            }} >Approve</APButton>
          </APRow>
        </> : <>

          <APRow>
            <APText variant="paragraph-large">We are sorry to see you go, If you wish to use our services again in the future, you can create a new account.</APText>
          </APRow>
          <APSizedBox height='16px' />

          <APCard>
            <APColumn crossAxisAlignment="start">
              <APText variant="h3">Feedback</APText>
              <APText variant="paragraph-small"> Please choose the reason for account deletion</APText>

              <APForm control={control}>
                <APFormFieldRadio label=""
                  initialValue={selectedOption}
                  items={[
                    {
                      value: "Duplicate Account",
                      label: "Duplicate Account"
                    },
                    {
                      value: "Hard to use the platform",
                      label: "Hard to use the platform"
                    },
                    {
                      value: "Change of Broker",
                      label: "Change of Broker"
                    },
                    {
                      value: "No Longer Interested",
                      label: "No Longer Interested"
                    },
                    {
                      value: "Poor Customer Service",
                      label: "Poor Customer Service"
                    },
                    {
                      value: "Other",
                      label: "Other"
                    }
                  ]}
                  onChanged={(v) => {
                    if (v) {
                      setSelectedOption(v);
                    }
                  }}
                />

                {
                  selectedOption === "Other" &&
                  <APFormFieldText label='Share why are you leaving..'
                    validator={(value) => {

                      if (value === "") {
                        return "Share why are you leaving..";
                      }

                      if (value.length < 10) {
                        return "Please enter at least 10 characters";
                      }

                      return null;
                    }}
                    onChanged={(v) => {
                      setCustomReason(v);
                    }}
                  />
                }

              </APForm>
            </APColumn>
          </APCard>

          <APSizedBox height='34px' />
          <APRow mainAxisAlignment="center">
            <APButton style={{ backgroundColor: "#00B0FF" }} onClick={async () => {
              if (selectedOption === "Other" && !(await control.validate())) {
                return;
              }
              const reason = selectedOption === "Other" ? customReason : selectedOption;

              try {
                const dialogResponse = await launchDialog<string>(deferred => <AccountDeletionOtpDialog deferred={deferred} requestId={requestId} reason={reason} />);
                if (dialogResponse === "success") {
                  launchPage("success");
                }
              } catch (error) {

              }
            }}>
              Continue to OTP
            </APButton>
          </APRow>

        </>}


      </APColumn>
    </APExpanded>
  )
}

function EmptyScreenWithMessage({ message, subMessage }: { message: string, subMessage?: string }) {
  return <APExpanded>
    <APColumn style={{ padding: "32px 16px", backgroundColor: APPalette["grey-50"] }} mainAxisSize="max" crossAxisAlignment="stretch" mainAxisAlignment="center">
      <APRow mainAxisAlignment='center'>
        <APImage width={135} height={18} src='/Assetplus_Logo.png' />
      </APRow>
      <APSizedBox height='16px' />
      <APRow mainAxisAlignment='center'>
        <APColumn crossAxisAlignment="center">
        <APText variant="paragraph-large">{message}</APText>
          {subMessage && <APText variant="paragraph-large">{subMessage}</APText>}
        </APColumn>
      </APRow>
    </APColumn>
  </APExpanded>
}


function AccountDeletionOtpDialog({ requestId, reason, deferred }: { requestId: string, reason: string, deferred: Deferred<string> }) {

  const [errorInOTPGeneration, setErrorInOTPGeneration] = useState(false);
  const [otpMessage, setOtpMessage] = useState<string | undefined>();
  const [otpValue, setOtpValue] = useState<undefined | string>();

  var [timeLeft, { start }] = useCountDown(60000, 1000);
  const [otpSent, setOtpSent] = useState(false);

  const formControl = useAPForm();

  async function generateOtpHelper() {
    try {
      const response = await getIt(MutualFundApiServices).generateApprovalOTP(requestId);
      setOtpMessage(response.message);
      setOtpSent(true);
      showAlertSnackBarConfirmation({ content: "OTP Sent", intent: "positive", emphasis: "intense" });
      start();
    } catch (error: any) {
      setErrorInOTPGeneration(true);
      await showErrorDialog(error.message);
    }
  }


  useEffect(() => {
    generateOtpHelper();
  }, [])


  return <APDialogCard title='OTP Verification' style={{ minWidth: "70%" }} >
    <APColumn mainAxisAlignment="center" crossAxisAlignment="stretch">
      {otpMessage &&
        <>
          <APText variant="paragraph-large" center>{otpMessage}</APText>
          <APSizedBox height='16px' />
        </>
      }
      <APForm control={formControl}>
        <APColumn mainAxisAlignment='center'>
          <APOtp digits={4} onChanged={(value) => setOtpValue(value)} validator={(value) => {
            if (value.length === 4) {
              return null;
            }
            return "Please enter 4 digit OTP";
          }} />
          <APSizedBox height='16px' />
          <APButton onClick={otpValue?.length === 4 ? async () => {
            try {
              await getIt(MutualFundApiServices).approveAccountDeletion({ requestId, otp: otpValue, reason });
              deferred.resolve("success");
            } catch (error: any) {
              showErrorDialog(error.message);
            }
          } : undefined}>Verify</APButton>
        </APColumn>
      </APForm>

      <APSizedBox height='16px' />
      <APRow gap='4px'>
        <APText variant="paragraph-medium">Didn’t receive?</APText>
        <APText variant="link-medium" onClick={
          (timeLeft > 0 && otpSent)
            ? undefined
            : async () => await generateOtpHelper()}>Resend OTP {(timeLeft / 1000 === 0 || errorInOTPGeneration) ? `?` : `${timeLeft / 1000} sec`}</APText>
      </APRow>
    </APColumn>
  </APDialogCard>
}