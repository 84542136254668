import { HoldingType, TaxStatus } from "../Models/Activation2Model";

export class HolderPANDetails {
    panNumber!: string;
    dob!: string;
}

export class BasicDetailsReqClass {
    token!: string;
    holdingType!: HoldingType;
    taxStatus!: TaxStatus;
    firstHolderPanDetails!: HolderPANDetails;
    secondHolderPanDetails?: HolderPANDetails
}

export class KYCDigilockerSchema {

    userId!: string

    kycType!: "NEW" | "MODIFICATION"

    digilockerStatus!: "PENDING" | "INITIATED" | "APPROVED"

    name!: string

    email!: string

    mobile!: string

    signzyOnboardingId!: string

    signzyUsername!: string

    signzyPassword!: string

    signzyUserId!: string

    signzyAccessToken!: string

    dob?: string

    fatherName?: string

    panNumber?: string

    panDocumentType?: string

    panImageBase64?: string

    address?: string

    city?: string

    state?: string

    pincode?: string

    district?: string

    aadhaarLast4Digits?: string

    gender?: "M" | "F" | "T"

    maritalStatus?: "MARRIED" | "UNMARRIED"

    annualIncome?: string

    motherName?: string

    occupationCode?: string
    occupationDescription?: string

    pep?: "NO" | "YES"

    rpep?: "NO" | "YES"

    photoBase64?: string

    signatureBase64?: string


    applicationStatus?: string


}

export class DeletionAccountDetails {
    fullName!: string;
    accountDeletedAlready!: boolean;
}

export class GeneratedApprovalOTP {
    message!: string;
}