import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import { APCenter, APColumn, APFormFieldDropdown, APFormFieldImage, APFormFieldText, APJourneyApp, APSizedBox, APTag, APText, Deferred, getIt, showSnackbar, APGrid, APImage, launchDialog, showErrorDialog, REGEX, showLoadingDialog } from 'ap-components';
import { useEffect, useRef, useState } from 'react';
import MutualFundApiServices from '../../Services/MutualFundServices';
import { DigilockerKYCIntro, OccupationTable, addParamsToCurrentUrl, launchUrl } from './common';
import SharedPrefs from '../../Services/SharedPrefs';
import { KYCDigilockerSchema } from '../../Services/APIClasses';



export function DigilockerKYCMobilePage(props: RouteComponentProps) {

    const location = useLocation();

    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    let isDigilockerCompleted = searchParams.get("kyc_digilocker_stage") == "redirected"
    let isKycEsignCompleted = searchParams.get("kyc_esign_stage") == "redirected"
    let kycStep = searchParams.get("kycStep");

    //62ea6ae730986100086ceefd

    if (!token) {
        return <APCenter><APTag variant='negative'>Invalid user</APTag></APCenter>
    }

    let [response, setResponse] = useState<KYCDigilockerSchema>();

    let [renderHash, setRenderHash] = useState(0);

    async function refetch() {
        try {
            let apiResp = await getIt(MutualFundApiServices).kyc({ token: token! });

            setResponse(apiResp);
        } catch (err: any) {
            showErrorDialog(err.message)
        }
    }

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setRenderHash(renderHash + 1);
    }, [response?.fatherName, response?.panNumber, response?.name, response?.dob,]);

    if (!response) {
        return <APCenter><APTag variant='info'>Loading...</APTag></APCenter>
    }

    let kycForm = response;


    if (kycForm.applicationStatus == "engineCalled") {
        return <APCenter><APImage style={{ width: "100%", maxWidth: "340px" }} src='/kyc/kyc_submitted.png'></APImage></APCenter>
    }



    return <APJourneyApp
        style={{ width: "100%", height: "100%" }}
        mode='linear'
        title="Mutual Fund KYC"
        description='Seamlessly complete your KYC via Digilocker'
        initialStepId={kycStep ?? undefined}
        steps={[
            {
                title: "Digilocker",
                description: "Authorize access to your Digilocker documents",
                form: <DigilockerKYCIntro kycForm={kycForm} />,
                onSubmit: async () => {
                    if (!isDigilockerCompleted && kycForm.digilockerStatus != "APPROVED") {
                        let { url } = await getIt(MutualFundApiServices).getDigilockerUrl({ token, redirectUrl: addParamsToCurrentUrl({ kyc_digilocker_stage: "redirected", kycStep: "Digilocker" }) });
                        launchUrl(url);
                        throw new Error("Redirecting to Digilocker");
                    } else {
                        await getIt(MutualFundApiServices).fetchDigilockerStatus({ token });
                        await refetch();
                    }
                }
            },
            {
                title: "Personal Details",
                description: "Additional details required for KYC",
                form: <APGrid count={1} columnGap={"16px"} key={"personal_details_" + renderHash}>
                    {kycForm.panDocumentType == "image"
                        && <APFormFieldImage
                            label="PAN Card"

                            src={kycForm.panImageBase64}
                            onChange={async (value) => {
                                if (value && value != kycForm.panImageBase64) {
                                    try {
                                        let resp = await showLoadingDialog(async () => await getIt(MutualFundApiServices).uploadPANImage({ token, panImageBase64: value }));
                                        kycForm.panImageBase64 = value;
                                        setResponse({
                                            ...kycForm,
                                            ...resp
                                        });
                                    } catch (err: any) {
                                        showErrorDialog(err.message);
                                        setRenderHash(renderHash + 1);
                                    }
                                    //Can't call refetch here, it's screwing up the form
                                    // await refetch();
                                }
                            }}
                            validator={(file) => {
                                if (!file) {
                                    return "Please upload a valid image";
                                }
                                return null;
                            }}
                        />
                    }
                    <APFormFieldText
                        label="Name"
                        // readOnly
                        key={"personal_details_name" + renderHash}
                        initialValue={kycForm.name}
                        onChanged={(value) => {
                            kycForm.name = value;
                        }}
                        validator={(value) => {
                            return null;
                        }}
                    />

                    <APFormFieldText
                        label="PAN"
                        initialValue={kycForm.panNumber}
                        // readOnly
                        key={"personal_details_pan" + renderHash}

                        onChanged={(value) => {
                            kycForm.panNumber = value;
                        }}
                        validator={(value) => {
                            if (!value || REGEX.PAN.test(value) == false) {
                                return "Please enter a valid PAN number";
                            }
                            return null;
                        }}
                    />

                    <APFormFieldText
                        label="Date of Birth"
                        // readOnly
                        key={"personal_details_dob" + renderHash}

                        initialValue={kycForm.dob}
                        onChanged={(value) => {
                            kycForm.dob = value;
                        }}
                        validator={(value) => {
                            if (!value || value.length < 10) {
                                return "Please enter a valid date";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldDropdown<"M" | "F" | "T"> label='Gender'
                        items={[{
                            value: "M",
                            label: "Male"
                        }, {
                            value: "F",
                            label: "Female"
                        },
                        {
                            value: "T",
                            label: "Transgender"
                        }
                        ]}
                        initialValue={kycForm.gender}
                        onChanged={(value) => {
                            if (value)
                                kycForm.gender = value;
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Please select gender";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldDropdown<"MARRIED" | "UNMARRIED"> label='Marital Status'
                        items={[{
                            value: "MARRIED",
                            label: "Married"
                        }, {
                            value: "UNMARRIED",
                            label: "Unmarried"
                        }]}
                        initialValue={kycForm.maritalStatus}
                        onChanged={(value) => {
                            if (value)
                                kycForm.maritalStatus = value;
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Please select marital status";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldDropdown label="Occupation"
                        items={OccupationTable}
                        initialValue={kycForm.occupationCode}
                        onChanged={(value) => {
                            if (value) {
                                kycForm.occupationCode = value;
                                kycForm.occupationDescription = OccupationTable.find(a => a.value === value)?.label!;
                            }
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Please select occupation";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldDropdown
                        label='Annual Income'
                        items={[
                            { value: "31", label: "Below 1 Lac" },
                            { value: "32", label: "1-5 Lacs" },
                            { value: "33", label: "5-10 Lacs" },
                            { value: "34", label: "10-25 Lacs" },
                            { value: "35", label: "25 Lacs-1 crore" },
                            { value: "36", label: "1 crore" },
                        ]}
                        initialValue={kycForm.annualIncome}
                        onChanged={(value) => {
                            if (value)
                                kycForm.annualIncome = value;
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Please select annual income";
                            }
                            return null;
                        }
                        }
                    />
                    <APFormFieldDropdown
                        label='Political Exposure'
                        items={[
                            { value: "n/a", label: "Not applicable" },
                            { value: "pep", label: "Politically Exposed Person" },
                            { value: "rpep", label: "Related to Politically Exposed Person" }
                        ]}
                        initialValue={!kycForm.pep ? undefined : (kycForm.pep == "YES" ? "pep" : kycForm.rpep == "YES" ? "rpep" : "n/a")}
                        onChanged={(value) => {
                            if (value == "n/a") {
                                kycForm.pep = "NO";
                                kycForm.rpep = "NO";
                            } else if (value == "pep") {
                                kycForm.pep = "YES";
                                kycForm.rpep = "NO";
                            } else if (value == "rpep") {
                                kycForm.pep = "NO";
                                kycForm.rpep = "YES";
                            }
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Please select political exposure";
                            }
                            return null;
                        }
                        }
                    />

                    <APFormFieldText
                        label="Father's Name"
                        initialValue={kycForm.fatherName}
                        key={"personal_details_father" + renderHash}
                        onChanged={(value) => {
                            kycForm.fatherName = value;
                        }}
                        validator={(value) => {
                            if (!value || value.length < 3) {
                                return "Please enter a valid name";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="Mother's Name"
                        initialValue={kycForm.motherName}
                        onChanged={(value) => {
                            kycForm.motherName = value;
                        }}
                        validator={(value) => {
                            if (!value || value.length < 3) {
                                return "Please enter a valid name";
                            }
                            return null;
                        }}
                    />

                </APGrid>,
                onSubmit: async () => {
                    await getIt(MutualFundApiServices).saveIdentityProof({ token, panNumber: kycForm.panNumber!, name: kycForm.name!, dob: kycForm.dob!, fatherName: kycForm.fatherName! });

                    await getIt(MutualFundApiServices).saveKYCDetails({
                        token,
                        gender: kycForm.gender!,
                        maritalStatus: kycForm.maritalStatus!,
                        annualIncome: kycForm.annualIncome!,
                        motherName: kycForm.motherName!,
                        occupationDescription: kycForm.occupationDescription!,
                        occupationCode: kycForm.occupationCode!,
                        pep: kycForm.pep!,
                        rpep: kycForm.rpep!,
                    });

                    await refetch();
                }
            },
            {
                title: "Address Details",
                description: "As per digilocker Aadhaar",
                form: <APColumn crossAxisAlignment='stretch'>

                    <APFormFieldText
                        label="Address Line"
                        multiline
                        initialValue={kycForm.address}
                        onChanged={(value) => {
                            kycForm.address = value;
                        }}
                        readOnly={kycForm.address != ""}
                        validator={(value) => {
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="City"
                        initialValue={kycForm.city}
                        onChanged={(value) => {
                            kycForm.city = value;
                        }}
                        // readOnly={kycForm.city != "" && (!!kycForm.city && kycForm.city.length <= 30)}
                        validator={(value) => {
                            if (value.length > 30) {
                                return "City name must be less than 30 chars"
                            }
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="District"
                        initialValue={kycForm.district}
                        onChanged={(value) => {
                            kycForm.district = value;
                        }}
                        readOnly={kycForm.district != ""}
                        validator={(value) => {
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="State"
                        initialValue={kycForm.state}
                        onChanged={(value) => {
                            kycForm.state = value;
                        }}
                        readOnly={kycForm.state != ""}
                        validator={(value) => {
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="Pincode"
                        initialValue={kycForm.pincode}
                        onChanged={(value) => {
                            kycForm.pincode = value;
                        }}
                        readOnly={kycForm.pincode != ""}
                        validator={(value) => {
                            return null;
                        }}
                    />
                </APColumn>,
                onSubmit: async () => {

                    await getIt(MutualFundApiServices).saveAddressProof({
                        token,
                        aadhaarLast4Digits: kycForm.aadhaarLast4Digits!,
                        address: kycForm.address!,
                        city: kycForm.city!,
                        state: kycForm.state!,
                        district: kycForm.district!,
                        pincode: kycForm.pincode!
                    });

                    await refetch();
                }
            },
            {
                title: "Photo & Sign",
                description: "Required for completing KYC",
                form: <APColumn crossAxisAlignment='center'>
                    <APText variant='h4'>Your photo</APText>
                    <APFormFieldImage
                        label='Photograph'
                        src={kycForm.photoBase64}
                        onChange={(value) => {
                            kycForm.photoBase64 = value;
                        }}
                        validator={(file) => {
                            if (!file) {
                                return "Please upload a valid image";
                            }
                            return null;
                        }}
                    />
                    <APText variant='h4'>Signature Image</APText>
                    <APFormFieldImage
                        label='Signature'
                        src={kycForm.signatureBase64}
                        onChange={(value) => {
                            kycForm.signatureBase64 = value;
                        }}
                        validator={(file) => {
                            if (!file) {
                                return "Please upload a valid image";
                            }
                            return null;
                        }}
                    />
                </APColumn>,
                onSubmit: async () => {

                    await getIt(MutualFundApiServices).savePhotoAndSignature({
                        token,
                        photoBase64: kycForm.photoBase64!,
                        signatureBase64: kycForm.signatureBase64!
                    });

                    await refetch();
                }
            },
            {
                title: "E-Sign",
                description: "Digitally sign your KYC application",
                form: <APColumn crossAxisAlignment='start'>
                    <APText variant='h3'>Review your documents and KYC details and digitally sign your KYC application using Aadhaar and OTP.</APText>
                    <APText>Step 1: Click on the checkbox to authorize e-sign</APText>
                    <APText>Step 2: Enter your Aadhaar number</APText>
                    <APText>Step 3: Enter OTP sent to phone linked to your Aadhaar</APText>
                </APColumn>,
                onSubmit: async () => {

                    if (!isKycEsignCompleted) {
                        let { url } = await getIt(MutualFundApiServices).generateEsignUrl({ token, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign" }) });
                        launchUrl(url);
                        throw new Error("Redirecting to Esign");
                    }
                    else {
                        try {
                            await getIt(MutualFundApiServices).executeEsign({ token });
                        } catch (err) {
                            let { url } = await getIt(MutualFundApiServices).generateEsignUrl({ token, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign" }) });
                            launchUrl(url);
                            throw new Error("Redirecting to Esign");
                        }
                        await getIt(MutualFundApiServices).submitApplication({ token });
                        await refetch();
                    }

                    showSnackbar("KYC application submitted successfully", "positive");
                }
            }
        ]}

    />
}
